@font-face {
    font-family: "InterBold";
    src: url("../fonts/inter/Inter-Bold.ttf");
}

@font-face {
    font-family: "InterSemiBold";
    src: url("../fonts/inter/Inter-SemiBold.ttf");
}

@font-face {
    font-family: "InterMedium";
    src: url("../fonts/inter/Inter-Medium.ttf");
}

@font-face {
    font-family: "InterRegular";
    src: url("../fonts/inter/Inter-Regular.ttf");
}

@font-face {
    font-family: "InterItalic";
    src: url("../fonts/inter/Inter-Italic.otf");
}

@font-face {
    font-family: "InterBoldItalic";
    src: url("../fonts/inter/Inter-BoldItalic.otf");
}

body {
    margin: 0 !important;
}

thead {
    background-color: #D8F0D4 !important;
}

thead .MuiTableCell-root {
    /*color: #b5b5c3 !important;*/
    color: #315CCB !important;
    font-family: InterBold !important;
    text-transform: capitalize !important;
    font-size: 12px !important;
    border-bottom: none !important;
}

tbody .MuiTableCell-root {
    color: #000 !important;
    /*color: #7e8299 !important;*/
    font-size: 12px !important;
    font-family: InterMedium !important;
    border-bottom: none !important;
}

thead .MuiTableRow-root {
    border: 1px solid #aaa !important
}

tbody .MuiTableRow-root {
    border: 1px solid #aaa !important
}

::-webkit-scrollbar {
    height: 5px;
    width: 7px
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aaaaaa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
}

.tableContainer {
    max-height: calc(100vh - 100px) !important;
}

.table tr th {
    background-color: #D8F0D4 !important;
}
