.imgView {
    height: 30px;
    width: 30px
}

.imgStyle {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.dataView {
    width: 20% !important;
}

.productNameTitleText {
    color: #000;
    font-family: InterBold !important;
    font-size: 13px !important;
}

.productNameText {
    color: #000;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.moqErrorText {
    color: red;
    font-family: InterBold !important;
    font-size: 12px !important;
}

.hsCodeText {
    color: #000;
    font-size: 12px !important;
    font-family: InterMedium !important;
}

.deliveryAddressText {
    color: #000;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.shippingDetailText {
    color: #000;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.orderQuantityText {
    color: #000;
    font-size: 12px !important;
    font-family: InterMedium !important;
}

.priceText {
    color: #000 !important;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.borderBottomLine {
    border-bottom: 1px solid #DDD;
}

.addToCartView {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border: 1px solid #DDD;
    height: 50px;
}

.pointerView {
    cursor: pointer;
}

.plusMinusNoText {
    color: #000;
    font-family: InterBold !important;
    font-size: 20px;
}

.borderRightLine {
    font-family: InterBold !important;
    border-right: 1px solid #DDD;
    height: 50px;
}

.borderLeftLine {
    font-family: InterBold !important;
    border-left: 1px solid #DDD;
    height: 50px;
}

.subPriceText {
    color: red;
    font-family: InterBold !important;
    font-size: 13px !important;
}

.subPricePerKgText {
    color: red;
    font-family: InterBold !important;
    font-size: 11px !important;
}

.calculationPendingText {
    color: red;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.cartTotalTitleText {
    color: #000;
    font-family: InterBold !important;
    font-size: 14px !important;
}

.paymentTermText {
    color: #000 !important;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.paymentTermDescText {
    color: red !important;
    font-size: 11px !important;
    font-family: InterMedium !important;
}
