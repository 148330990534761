.mainLabelText {
    font-size: 20px !important;
    font-family: InterBold !important;
    color: #000 !important;
}

.borderView {
    border: 1px solid #aaa !important
}

.labelView {
    border: 1px solid #aaa !important;
    background-color: #D8F0D4 !important;
    min-height: 60px !important;
}

.labelText {
    font-family: InterBold !important;
    color: #000 !important;
    font-size: 16px !important;
}

.btnView {
    cursor: pointer !important;
}
