.mainLabelText {
    font-size: 20px !important;
    font-family: InterMedium !important;
    color: #000 !important;
}

.borderView {
    border: 1px solid #aaa !important
}

.labelView {
    border: 1px solid #aaa !important;
    background-color: #D8F0D4 !important;
    min-height: 60px !important;
}

.labelText {
    font-family: InterBold !important;
    color: #000 !important;
    font-size: 16px !important;
}

.pickupLabelText {
    font-family: InterBold !important;
    color: #EE2525 !important;
    font-size: 15px !important;
}

.preshipmentLabelText {
    font-family: InterBold !important;
    color: #315CCB !important;
    font-size: 15px !important;
}

.pickupSubLabelText {
    font-family: InterBold !important;
    color: #000 !important;
    font-size: 14px !important;
}

.preshipmentSubLabelText {
    font-family: InterBold !important;
    color: #000 !important;
    font-size: 14px !important;
}

.pickUpBorderView {
    border: 1px solid #EE2525 !important;
}

.preshipmentBorderView {
    border: 1px solid #315CCB !important;
}

.btnView {
    cursor: pointer !important;
}
