.manageInventoryText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 18px !important;
}

.skuBoxView {
    border: 1px solid #e1e3ea !important;
    background-color: #fff !important;
    border-radius: 5px !important;
    height: 40px !important
}

.skuText {
    color: #000 !important;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.productCountView {
    background-color: #000 !important;
    border-radius: 3px !important;
    height: 40px !important;
}

.productCountText {
    color: #fff !important;
    font-size: 15px !important;
    font-family: InterBold !important;
}

.productTitleText {
    color: #fff !important;
    font-size: 14px !important;
    font-family: InterBold !important;
}
