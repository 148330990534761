.cardDiv {
    background-color: #FFFFFF !important;
    border-color: #FFF !important;
    border-radius: 10px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.borderBottomLine {
    border-bottom: 1px solid #DDD;
}

.uploadDocumentText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 15px !important;
}

.errorDocument {
    color: red !important;
    font-size: 12px !important;
    font-family: InterMedium !important;
}

.addMoreText {
    cursor: pointer !important;
    color: #315CCB !important;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.docLabelText {
    color: #000 !important;
    font-size: 15px !important;
    font-family: InterMedium !important;
    margin-bottom: 6px !important;
}

.requiredText {
    color: red !important;
    font-family: InterMedium !important;
    font-size: 14px !important;
}

.labelText {
    color: #315CCB !important;
    font-family: InterBold !important;
    text-transform: capitalize !important;
    font-size: 13px !important;
}

.subLabelText {
    color: #315CCB !important;
    font-size: 11px !important;
    font-family: InterMedium !important;
    text-transform: capitalize !important;
}
