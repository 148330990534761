.imgView {
    height: 30px;
    width: 30px
}

.imgStyle {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.dataView {
    width: 20% !important
}

.productNameTitleText {
    color: #000;
    font-family: InterBold !important;
    font-size: 13px !important;
}

.hsCodeText {
    color: #000;
    font-size: 12px !important;
    font-family: InterMedium !important;
}

.productNameText {
    color: #000;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.productCountText {
    color: #000;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.orderQuantityText {
    color: #000;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.timestampText {
    color: #000;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.priceText {
    color: #000 !important;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.borderBottomLine {
    border-bottom: 1px solid #DDD;
}

.pointerView {
    cursor: pointer;
}

.subPriceText {
    color: red;
    font-family: InterBold !important;
    font-size: 13px !important;
}

.subPricePerKgText {
    color: red;
    font-family: InterBold !important;
    font-size: 11px !important;
}

.moqErrorText {
    color: red;
    font-family: InterMedium !important;
    font-size: 12px !important;
}
