.cardDiv {
    background-color: #FFFFFF !important;
    border-color: #FFF !important;
    border-radius: 10px !important;
}

.addressMainText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 15px !important;
}

.borderBottomLine {
    border-bottom: 1px solid #DDD;
}

.moqErrorText {
    color: red !important;
    font-family: InterBold !important;
    font-size: 12px !important;
}
