.mainView {
    background-color: #FFFFFF !important;
    border: 1px solid #aaa !important;
    border-radius: 2px !important;
}

.paymentTermText {
    color: #000 !important;
    font-size: 14px !important;
    font-family: InterMedium !important;
}

.paymentTermDescText {
    color: red !important;
    font-size: 11px !important;
    font-family: InterMedium !important;
}
