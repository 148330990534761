.dataView {
    width: 20% !important;
}

.labelText {
    color: #315CCB !important;
    font-family: InterBold !important;
    text-transform: capitalize !important;
    font-size: 13px !important;
}

.subLabelText {
    color: #315CCB !important;
    font-size: 11px !important;
    text-transform: capitalize !important;
    font-family: InterMedium !important;
}

.tableHeadRow {
    border: 1px solid #aaa !important;
}

.tableRow {
    background-color: #FFFFFF !important;
    border: 1px solid #aaa !important;
}
