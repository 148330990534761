.manageOrderText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 18px !important;
}

.borderView {
    background-color: #FFFFFF !important;
    border: 1px solid #aaa !important;
    -webkit-box-shadow: 0 2px 5px #aaa !important;
    -moz-box-shadow: 0 2px 5px #aaa !important;
    box-shadow: 0 2px 5px #aaa !important;
}

.orderIdBoxView {
    border: 1px solid #e1e3ea !important;
    background-color: #fff !important;
    border-radius: 5px !important;
    height: 40px !important
}

.orderIdText {
    color: #000 !important;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.orderCountView {
    background-color: #000 !important;
    border-radius: 3px !important;
    height: 40px !important;
}

.orderCountText {
    color: #fff !important;
    font-size: 15px !important;
    font-family: InterBold !important;
}

.orderTitleText {
    color: #fff !important;
    font-size: 14px !important;
    font-family: InterBold !important;
}

.borderBottomView {
    border: 1px solid #ddd !important;
}
