.cardDiv {
    background-color: #fff !important;
    border: 1px solid #aaa !important;
    -webkit-box-shadow: 0 2px 5px #aaa !important;
    -moz-box-shadow: 0 2px 5px #aaa !important;
    box-shadow: 0 2px 5px #aaa !important;
    cursor: pointer !important;
    min-height: 120px !important;
}

.dashboardText {
    color: #55AC10 !important;
    font-family: InterBold !important;
    font-size: 21px !important
}

.titleText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 15px !important;
}

.countBorderBottomView {
    border-bottom: 1px solid #999;
}
