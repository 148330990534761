.addressMainView {
    background-color: #FFFFFF !important;
    border: 1px solid #aaa !important;
    border-radius: 2px !important;
}

.labelText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 15px !important;
}

.valueText {
    color: #000 !important;
    font-size: 15px !important;
    font-family: InterMedium !important;
}
