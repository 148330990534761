.mainView {
    background-color: #FFFFFF !important;
    border-color: #FFF !important;
    border-radius: 10px !important;
}

.volumeTitleText {
    color: #000 !important;
    font-family: InterBold !important;
    text-transform: lowercase !important;
    font-size: 13px !important;
}

.labelText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 15px !important;
}

.valueText {
    color: #000 !important;
    font-size: 14px !important;
    font-family: InterMedium !important;
}
