.customerText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 18px !important;
}

.agbinBoxView {
    border: 1px solid #e1e3ea !important;
    background-color: #fff !important;
    border-radius: 5px !important;
    height: 40px !important
}

.agbinText {
    color: #000 !important;
    font-size: 13px !important;
    font-family: InterMedium !important;
}

.countView {
    background-color: #000 !important;
    border-radius: 3px !important;
    height: 40px !important;
}

.countText {
    color: #fff !important;
    font-size: 15px !important;
    font-family: InterBold !important;
}

.countTitleText {
    color: #fff !important;
    font-size: 14px !important;
    font-family: InterBold !important;
}

.borderBottomView {
    border: 1px solid #ddd !important;
}
